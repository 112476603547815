<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
            <!-- <v-spacer></v-spacer>
            <v-btn icon color="purple" @click="openCreateUserDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="purple" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-user-settings' }">
                        <v-list-item-content>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No users yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <UserListItem :attr="item" :link="!selectOne"></UserListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-row justify="center" class="py-5" v-show="displayCreateUser">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createUserForm" ref="createUserFormRef" @submit="validateCreateUser" onSubmit="return false;" @keyup.enter.native="validateCreateUser">
                    <div v-if="add">
                        <v-select :items="addUserChoices" v-model="addUserId" label="Select an user"></v-select>
                    </div>
                    <div v-if="create">
                        <!--
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="user" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        -->
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newUserName
                            label="Display Name"
                            :rules="newUserNameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="The name for the user"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="purple white--text" @click="addUser" :disabled="!createUserForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="purple white--text" @click="createUser" :disabled="!createUserForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
        <!-- <CreateUserDialog v-model="createUserDialogVisible" @cancel="createUserDialogVisible = false" @created="createUserDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import UserListItem from '@/components/list-item/UserListItem.vue';
// import CreateUserDialog from '@/components/service-dashboard/CreateUserDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        UserListItem,
        // CreateUserDialog,
    },

    props: {
    },

    data() {
        return {
            list: [],
            createUserDialogVisible: false,
            // create user
            displayCreateUser: false,
            createUserForm: null,
            newUserName: null,
            newUserNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'User name is required',
            ],
            // add user
            addUserChoices: [],
            addUserId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an user';
            }
            switch (this.list.length) {
            case 0:
                return 'No users';
            case 1:
                return '1 user';
            default:
                return `${this.list.length} users`;
            }
        },
    },

    watch: {
        displayCreateUser(value) {
            if (value && (this.add || this.create)) {
                this.initAddUserChoices();
            }
        },
    },

    methods: {
        async loadUserList() {
            try {
                this.$store.commit('loading', { loadUserList: true });
                const response = await this.$client.account(this.$route.params.accountId).user.list();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadUserList failed', err);
            } finally {
                this.$store.commit('loading', { loadUserList: false });
            }
        },
        onClickItem(userId) {
            if (this.selectOne) {
                this.$emit('selected', { userId });
            }
        },
        openCreateUserDialog() {
            this.createUserDialogVisible = true;
        },
        async createUser() {
            this.error = false;
            console.log('createUser');
            const request = {
                // name: this.name,
                name: this.newUserName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if user arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the user to an appropriate location after user is created
            };
            const response = await this.$client.account(this.$route.params.accountId).user.create(request);
            console.log('createUser response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const user = { id, ...request };
                this.list.push(user);
                this.$emit('created-user', user);
                this.$emit('added-user', user);
                this.displayCreateUser = false;
                this.newUserName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateUser() {
            if (this.$refs.createUserFormRef.validate()) {
                this.createUser();
            }
        },

        async addUser() {
            const user = { id: this.addUserId, label: this.addUserChoices.find((item) => item.value === this.addUserId).text };
            this.list.push(user);
            this.$emit('added-user', user);
            this.displayCreateUser = false;
            this.addUserId = null;
        },
        async initAddUserChoices() {
            // create a map of which user ids are already in the list
            const userMap = {};
            this.list.forEach((item) => {
                userMap[item.id] = true;
            });
            // when we show the add/create user dialog, load the list of available users to add to the policy (list of all users less the ones already in the policy)
            const result = await this.$client.account(this.$route.params.accountId).user.list();
            console.log(`UserViewList.vue fetch users result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove users already in the policy, then convert the available users to a choice list for the v-select [ { text, value }, ... ]
                this.addUserChoices = result.list.filter((item) => !userMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    mounted() {
        this.loadUserList();
    },
};
</script>
